import "@/styles/ui_components/_tabs.scss"

const TABLIST_SELECTOR = ".ui-component-tabs [role='tablist']"
const TAB_SELECTOR = ".ui-component-tabs button[role='tab']"
const ARIA_SELECTED = "aria-selected"
const HIDDEN = "hidden"

class Tab {
  button: HTMLButtonElement
  private panel: HTMLElement
  private tablist: Tablist

  constructor(button: HTMLButtonElement, panel: HTMLElement, tablist: Tablist) {
    this.button = button
    this.panel = panel
    this.tablist = tablist
    this.button.addEventListener("click", this.activate.bind(this))
  }

  activate(): void {
    this.tablist.activeTab?.deactivate()
    this.button.setAttribute(ARIA_SELECTED, "true")
    this.button.focus()
    this.panel.removeAttribute(HIDDEN)
  }

  deactivate(): void {
    this.button.setAttribute(ARIA_SELECTED, "false")
    this.panel.setAttribute(HIDDEN, "")
  }

  get isActive(): boolean {
    return this.button.getAttribute(ARIA_SELECTED) === "true" && !this.panel.getAttribute(HIDDEN)
  }
}

class Tablist {
  private tabs: Tab[]

  constructor(tablist: HTMLElement) {
    this.tabs = [...tablist.querySelectorAll<HTMLButtonElement>(TAB_SELECTOR)].reduce(
      (tabs, tab) => {
        const panelId = tab.getAttribute("aria-controls")
        const panel = panelId && document.getElementById(panelId)

        return panel ? [...tabs, new Tab(tab, panel, this)] : tabs
      },
      [] as Tab[]
    )

    tablist.addEventListener("keydown", this.activateTab.bind(this))
  }

  get activeTab(): Tab | undefined {
    return this.tabs.find((tab) => tab.isActive)
  }

  activateTab(e: KeyboardEvent): void {
    const ARROW_LEFT = "ArrowLeft"
    const ARROW_RIGHT = "ArrowRight"

    if (e.key !== ARROW_LEFT && e.key !== ARROW_RIGHT) return

    const focusedIndex = [...this.tabs].findIndex((tab) => tab.button === document.activeElement)
    const offset = e.key === ARROW_LEFT ? -1 : 1
    const newIndex = Tablist.wrapIndex(focusedIndex + offset, this.maxIndex)

    this.tabs[newIndex].activate()
  }

  private get maxIndex(): number {
    return this.tabs.length - 1
  }

  private static wrapIndex(index: number, max: number): number {
    if (index > max) {
      return 0
    } else if (index < 0) {
      return max
    } else {
      return index
    }
  }
}

const setupTabs = (): void => {
  document
    .querySelectorAll<HTMLElement>(TABLIST_SELECTOR)
    .forEach((tablist) => new Tablist(tablist))
}

export { setupTabs }
